$( document ).ready(function() {

  if($("#menu").length != 0){
    $("#menu").followWhen(153);
  }

  if($("#mobile-menu").length != 0){
    $("#mobile-menu").followWhen(386);
  }


	$(".wrap_desktop").camera({
      loader: "none",
      navigation: false,
      fx: 'scrollHorz',
      pagination: true,
      height:'700',
      alignment: 'center',
      autoAdvance: true,
      hover: false,
      playPause: false,
      time: '3000',
      alignment: 'center',
      // pauseOnClick: false
   });  
  $(".wrap_desktop2").camera({
      loader: "none",
      navigation: false,
      fx: 'scrollHorz',
      pagination: false,
      height:'700',
      alignment: 'center',
      autoAdvance: true,
      hover: false,
      playPause: false,
      time: '1500',
      alignment: 'center',
      // pauseOnClick: false
   });  

   $(".wrap_mobile").camera({
      loader: "none",
      navigation: false,
      fx: 'scrollHorz',
      pagination: true,
      alignment: 'center',
      autoAdvance: true,
      hover: false,
      playPause: false,
      time: '500',
      alignment: 'center',
      // pauseOnClick: false
   }); 

   $(".wrap_mobile2").camera({
      loader: "none",
      navigation: false,
      fx: 'scrollHorz',
      pagination: false,
      alignment: 'center',
      autoAdvance: true,
      hover: false,
      playPause: false,
      time: '500',
      alignment: 'center',
      // pauseOnClick: false
   }); 
  if($(".sidebars").length != 0)
  {
    // All sides
    var sides = ["left", "top", "right", "bottom"];
    //$("h1 span.version").text($.fn.sidebar.version);
  
    // Initialize sidebars
    for (var i = 0; i < sides.length; ++i) {
        var cSide = sides[i];
        $(".sidebar." + cSide).sidebar({side: cSide});
    }

    // Click handlers
    $(".btn[data-action]").on("click", function () {
        var $this = $(this);
        var action = $this.attr("data-action");
        var side = $this.attr("data-side");
        $(".sidebar." + side).css("display", "block");
        $(".sidebar." + side).trigger("sidebar:" + action);
        return false;
    });
  }
   $('a[href^="#"]').on('click',function (e) {
    
    if(!$(this).hasClass("exclude")) {
        
        
        e.preventDefault();

        var target = this.hash;
        var $target = $(target);

        $('html, body').stop().animate({
            'scrollTop': $target.offset().top - 84
        }, 900, 'swing', function () {
        });
    }

  }); 
   $('.item').matchHeight();
});